<template>
<div class="cardItem">
    <div class="img" :style="backgroundImage"></div>
    <div class="card-inner">
        <div class="title" v-if="item.title">
            {{ escapeQuote(item.title) }}
        </div>
        <div class="description" v-if="item.description">
            <span class="text">
                {{ escapeQuote(item.description) }}
            </span>
            <a class="btn" v-if="item.permalink" :href="item.permalink">
                {{ item.button | buttonText }}
            </a>
        </div>
    </div>
</div>
    
</template>

<script>
export default {
   props: [
       'item', 
       'active'
    ],
    computed: {
        backgroundImage() {
            return { 'background-image': 'url(' + this.item.url  + ')'}
        }
    },
    methods: {
        escapeQuote(str) {
            return str.replace(/"/g, '\\"');
        }
    },
    filters: {
        buttonText: (value) => {
            return value.length > 0 ? value : 'Learn More';
        }
    },
    mounted() {
        //console.log(this.item);
    }, 
}
</script>

<style lang="scss" scoped>
    .cardItem {
        position: relative;
        width: 100%;
        height: 600px;
        background-color:slategray;
        overflow: hidden;

        .img {
            width: 100%;
            height: 600px;
            background-position: center;
            background-size: cover;
        }

        .title {
            position: absolute;
            top: 50px;
            padding: 5px 20px;
            width: calc(100% - 20px);
            /* height: 40px; */
            font-size: 1.6rem;
            color: #FFF;
            background-color: rgba($color: #000, $alpha: .5);
        }

        .description {
            position: absolute;
            bottom: 50px;
        }
        .description .text {
            padding: 20px;
            color: white;
            display: block;
            background-color: rgba($color: #000, $alpha: .5);
        }

        .btn {
            background-color: rgba($color: #000, $alpha: 1);
            font-size: 18px;
            color: #fff;
            padding: 13px 20px;
            font-weight: 500;
            margin-top: 30px;
        }
    }

</style>