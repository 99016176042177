<template>
  <div id="app">
    <CardList/>
  </div>
</template>

<script>
import CardList from './components/CardList.vue'
export default {
    name: 'App',
    components: {
        CardList
    }, 
    data() {
        return {
            'stuff': 'things'
        }
    }
}
</script>

<style>

</style>